import React from 'react';
import {AppServicesSection,AppServicesContent,AppServicesWrapper,
    AppServicesLeftCol,AppServicesRightCol,
    AppServicesCenter,CenterCol
} from './appservices.style';
import {Container, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const AppServicesPage = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                servicesPage2Json{
                    AppServices{
                        AppServicesContent1{
                            AppServicesLeftBold
                            text
                            AppServicesImg
                        }
                        AppServicesContent2{
                            AppServicesMobile{
                                childImageSharp{
                                    fluid(quality: 100){
                                    ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                        AppServicesContent3{
                            AppServicesLeftBold
                            text
                            AppServicesImg
                        }
                    }
                }
            }
      `);
      const AppServicesData = JSONData.servicesPage2Json.AppServices;
    return (
        <AppServicesSection id="featuresContainer">
            <Container>
                <AppServicesContent>
                    <Col md="6" lg="4">
                    {
                        AppServicesData.AppServicesContent1.map((item,idx) => {
                        return <AppServicesWrapper bottomMargin = {idx === 2 ? "0px":"30px"}>
                                <AppServicesLeftCol>
                                        <h5><span>{item.AppServicesLeftBold}</span><img src={item.AppServicesImg} alt=""/></h5>
                                        <p>{item.text}</p>
                                </AppServicesLeftCol>

                            </AppServicesWrapper>
                        })
                    }
                    </Col>

                    <CenterCol md="0" lg="4">
                        <AppServicesCenter>
                            <GatsImg
                                fluid={AppServicesData.AppServicesContent2.AppServicesMobile.childImageSharp.fluid}
                                className="AppServicesMobile"
                                imgStyle={{
                                    marginBottom:"0px",
                                    width:"100%"
                                }}
                                alt=""
                            />
                        </AppServicesCenter>
                    </CenterCol>

                    <Col md="6" lg="4">
                    {
                        AppServicesData.AppServicesContent3.map((item,idx) => {
                            return <AppServicesWrapper bottomMargin = {idx === 2 ? "0px":"30px"}>
                                <AppServicesRightCol>
                                        <h5><img src={item.AppServicesImg} alt=""/><span>{item.AppServicesLeftBold}</span></h5>
                                        <p>{item.text}</p>
                                </AppServicesRightCol>
                            </AppServicesWrapper>
                        })
                    }
                    </Col>
                </AppServicesContent>
            </Container>
        </AppServicesSection>
    );
}

export default AppServicesPage;
