import styled from 'styled-components';
import {device} from '../../../components/Common/device';
import {CommonbtnRound} from '../../../components/Common/common.style';
import {Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export const ServicesOverviewSection = styled.section`
    padding:100px 0px;

    @media ${device.tablet} {
        padding:80px 5px;
    }
`;

export const CustomRow = styled(Row)`
    align-items:center;

    @media ${device.laptop} {
        flex-direction:column;
    }
`;

export const ImageHolder = styled.div`
    padding-right:10px;

    @media ${device.laptop} {
        padding-right:0px;
        margin-bottom:40px;
    }
`;

export const TextLayout = styled.div`
    padding-left:10px;

    @media ${device.laptop} {
        padding-left:0px;
    }

    h2{
        text-align:left;
        margin-bottom:20px;
    }
    p{

    }
`;


export const FeaturesLayout = styled.div`
    margin-top:10px;

    img{
        height: 50px;
        margin-bottom: 15px;
    }
`;

export const FeaturesTextLayout = styled.div`
    margin-left:0px;

    h5{
        margin-bottom: 5px;
    }

    img{
        width:50px;
        height:50px;

        @media ${device.mobileM} {
            width:40px;
            height:40px;
        }
    }

    p{
        margin-bottom:0px;
    }
`;

export const FreeQuoteBtn = styled(CommonbtnRound)`
    margin-top:30px;
    border-radius:25px;
`;
