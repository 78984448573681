import React from 'react';
import {OurProcessSection,ServicesLayout,HeadingLayout,
    ServicesSingleLayout,ServicesBtn,RightImageHolder
} from './ourprocess.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";
import ButtonArrow from "../../../components/Common/buttonarrow"

const OurProcess  = () => {
    const JSONData  = useStaticQuery(graphql`
    query {
        servicesPage2Json{
            OurProcess{
                OurProcessHeading
                ServiceImg1
                ServiceHeading1
                ServiceText1
                ServiceImg2
                ServiceHeading2
                ServiceText2
                ServiceImg3
                ServiceHeading3
                ServiceText3
                ServiceImg4
                ServiceHeading4
                ServiceText4
                ServicesBtn
                RightImage{
                    childImageSharp{
                        fluid(quality: 100){
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
  `);
  const OurProcessData = JSONData.servicesPage2Json.OurProcess;
    return (
       <OurProcessSection>
           <Container>
                <Row>
                    <Col lg={6}>
                        <ServicesLayout>
                            <HeadingLayout>
                                <h2>
                                    {OurProcessData.OurProcessHeading}
                                </h2>
                            </HeadingLayout>
                            <Row>
                                <Col md={6} sm={6}>
                                    <ServicesSingleLayout>
                                        <img
                                                src={OurProcessData.ServiceImg1}
                                                alt=""
                                            />
                                        <h5>
                                        {OurProcessData.ServiceHeading1}
                                        </h5>
                                        <p>
                                        {OurProcessData.ServiceText1}
                                        </p>
                                    </ServicesSingleLayout>
                                </Col>
                                <Col md={6} sm={6}>
                                    <ServicesSingleLayout>
                                        <img
                                                src={OurProcessData.ServiceImg2}
                                                alt=""
                                            />
                                        <h5>
                                        {OurProcessData.ServiceHeading2}
                                        </h5>
                                        <p>
                                        {OurProcessData.ServiceText2}
                                        </p>
                                    </ServicesSingleLayout>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} sm={6}>
                                    <ServicesSingleLayout>
                                        <img
                                                src={OurProcessData.ServiceImg3}
                                                alt=""
                                            />
                                        <h5>
                                        {OurProcessData.ServiceHeading3}
                                        </h5>
                                        <p>
                                        {OurProcessData.ServiceText3}
                                        </p>
                                    </ServicesSingleLayout>
                                </Col>
                                <Col md={6} sm={6}>
                                    <ServicesSingleLayout>
                                        <img
                                                src={OurProcessData.ServiceImg4}
                                                alt=""
                                            />
                                        <h5>
                                        {OurProcessData.ServiceHeading4}
                                        </h5>
                                        <p>
                                        {OurProcessData.ServiceText4}
                                        </p>
                                    </ServicesSingleLayout>
                                </Col>
                            </Row>
                            <ServicesBtn>
                            <span>{OurProcessData.ServicesBtn}</span><ButtonArrow/>
                            </ServicesBtn>
                        </ServicesLayout>
                    </Col>
                    <Col lg={6}>
                        <RightImageHolder>
                            <GatsImg
                                fluid={OurProcessData.RightImage.childImageSharp.fluid}
                                alt=""
                                className="RightImage"
                            />
                        </RightImageHolder>
                    </Col>
                </Row>
           </Container>
       </OurProcessSection>
    );
}
export default OurProcess;
