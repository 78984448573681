import styled from 'styled-components';
import {device} from '../../../components/Common/device';
import {Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export const AppServicesSection = styled.section`
    padding: 100px 0px 80px;
    background: #f9fafc;
    background-size:100%;
    position:relative;

    @media ${device.laptop} {
        padding: 100px 0px;
    }

    @media ${device.tablet} {
        padding:80px 5px;
    }
    @media ${device.mobileXL} {
        padding:70px 5px 40px;
    }
`;

export const AppServicesContent = styled(Row)`
    align-items: center;
`;

export const CenterCol = styled(Col)`
    @media ${device.laptop}{
        display:none;
    }
`;

export const AppServicesWrapper = styled.div`
    display:flex;
    align-items:center;
    margin-bottom:${props => props.bottomMargin};
    border-radius: 60px;
    border: 0px solid #ddd;
    padding: 10px;

    @media ${device.laptop} {
        border-radius: 70px;
        margin-bottom: 30px;

        :last-child{
            margin-bottom:0px;
            padding-bottom:0px;
        }
        :first-child{
            margin-top:0px;
            padding-top:0px;
        }
    }

    @media ${device.mobileXL} {
        :last-child{
            margin-bottom:30px;
            padding-bottom:10px;
        }
        :first-child{
            margin-top:0px;
            padding-top:10px;
        }
    }

`;

export const AppServicesText = styled.div`
    font-size: 14px;
    line-height: 24px;
    color:#595959;
    margin: 10px;

`;



export const AppServicesLeftCol = styled.div`
    text-align:right;
    h5{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 10px;
        text-align:right;
    }
    img{
        margin-left: 10px;
        height: 30px;
    }
    p{
        margin-bottom:0px;
    }

    @media ${device.tablet} {
        text-align:left;
        h5{
            justify-content:flex-start;
            text-align:left;
        }
        h5 span{
            order:2;
        }
        h5 img{
            margin-left:0px;
            margin-right:10px;
        }
    }
`;


export const AppServicesRightCol = styled.div`
    text-align:left;
    h5{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;
    }
    img{
        margin-right: 10px;
        height: 30px;
    }
    p{
        margin-bottom:0px;
    }
`;


export const AppServicesCenter = styled.div`
    text-align: center;

    .AppServicesMobile{

    }
`;
