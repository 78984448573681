import React,{Component} from 'react';
import {Container} from 'react-bootstrap';
import {
    ClientSection,
    ClientLogoLayout,
    ClientLogoHolder,
    ClientLogo
} from './clients.style';
import { StaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Clients extends Component{

    render() {
        const settings = {
            arrows:false,
            infinite: true,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 2000,

            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 425,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                }
            ]
        };

        return (
            <ClientSection>
                <Container>
                    <ClientLogoLayout>
                        <Slider ref={c => (this.slider = c)} {...settings}>
                        {
                            this.props.ClientsData.ClientLogoDetails.map((clientObj, idx) => {
                                return  <ClientLogoHolder>
                                    <ClientLogo>
                                        <GatsImg
                                            fluid={clientObj.ClientLogo.childImageSharp.fluid}
                                            alt=""

                                        />
                                    </ClientLogo>
                                </ClientLogoHolder>
                            })
                        }
                        </Slider>
                    </ClientLogoLayout>
                </Container>
            </ClientSection>
        );
    }

}
const ClientsSection = () => (
    <StaticQuery
        query={graphql`
            query {
                servicesPage2Json{
                    Clients{
                        ClientLogoDetails{
                            ClientLogo{
                                childImageSharp{
                                    fluid(quality: 100){
                                    ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={(data) => (
            <Clients ClientsData={data.servicesPage2Json.Clients}/>
        )}
    />
);

export default ClientsSection;