import React from "react";
import HeaderMenuBS from "../components/HeaderMenuBS"
import Banner from "../components/CommonBanner"
import BackgroundImg from '../assets/services-2-images/banner.jpg';
import ServicesOverview from "../containers/ServicesPage2/ServicesOverview"
import AppServices from "../containers/ServicesPage2/AppServices"
import OurProcess from "../containers/ServicesPage2/OurProcess"
import CTABanner from "../containers/ServicesPage2/CTABanner"
import Clients from "../containers/ServicesPage2/Clients"
import Testimonials from "../containers/ServicesPage2/Testimonials"
import Footer from "../components/Footer"
import SEO from "../components/seo";
import Layout from "../components/layout";
import CTABackgroundImg from '../assets/services-2-images/small-banner.jpg';

const ServicesPage2 = () => (
    <Layout>
        <SEO 
            title="Beryl - Business, Corporate Gatsbyjs Website | Gatsbyjs Website Themes"
            description="Buy Responsive Business and Corporate Themes built with GatsbyJS, React Bootstrap and Styled-Components"
        />
        <HeaderMenuBS/>
        <Banner 
            BackgroundImg={BackgroundImg}
            pageTitle="Our Services"
            breadCrumbsFirst="Home"
            breadCrumbsSecond="Our Services"
            breadCrumbsFirstLink="/"
            breadCrumbsSecondLink="/services-2"
        />
        <ServicesOverview />
        <AppServices />
        <OurProcess />
        <CTABanner 
            BackgroundImg={CTABackgroundImg}
        />
        <Clients />
        <Testimonials />
        <Footer templateType="type2"/>
    </Layout>
)

export default ServicesPage2;