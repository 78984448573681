import styled from 'styled-components';
import {CommonbtnRound} from '../../../components/Common/common.style';
import {device} from '../../../components/Common/device';
import {ArrowRightAlt} from '@styled-icons/material/ArrowRightAlt';

export const OurProcessSection = styled.section`
    padding:100px 0px;

    @media ${device.tablet} {
        padding:80px 5px;
    }
`;

export const ServicesLayout = styled.div`
    padding-right:20px;

    @media ${device.laptop} {
        padding-right:0px;
        margin-bottom:40px;
    }
`;

export const HeadingLayout = styled.div`
    margin-bottom:20px;
`;


export const ServicesSingleLayout = styled.div`
    margin-bottom:40px;
    img{
        height:45px;
        margin-bottom:15px;
    }
    h5{
        margin-bottom: 5px;
    }
    p{

    }
`;


export const ServicesBtn = styled(CommonbtnRound)`
    border-radius:25px;
`;

export const RightImageHolder = styled.div`
    .RightImage{
        min-height:520px;
        @media ${device.laptop} {
            height:300px;
        }

        @media ${device.tablet} {
            min-height:auto;
            height:auto;
        }
    }
`;

export const RightArrow = styled(ArrowRightAlt)`
    width: 25px;
    height: 25px;
    margin-left: 5px;
`;
