import styled from 'styled-components';
import {device} from '../../../components/Common/device';
import SectionCircleImg from '../../../assets/DesignIcons/circle-pattern-1.png';

export const ClientSection = styled.section`
    padding:50px 0px;
    overflow:hidden;
    position:relative;
    background:#fafafa;
    :before{
        content: "";
        display: block;
        position: absolute;
        top: -60px;
        background-size: 100%;
        width: 150px;
        height: 150px;
        left: 0px;
        background-position-x: -60px;
        background-repeat: no-repeat;
        background-image: url(${SectionCircleImg});
    }
    @media ${device.laptop} {
        padding:80px 5px;
    }
`;

export const ClientLogoLayout = styled.div`
    .slick-list{
        line-height:0;
    }
`;

export const SliderOuterWrapper = styled.div`
    margin-left:-15px;
    margin-right:-15px;
`;

export const ClientLogoHolder = styled.div`
    width:20%;
    text-align:center;
    padding:0px 15px;

    @media ${device.laptop} {
        width:50%;
        margin-bottom:30px;

        :last-child{
            width:100%;
        }
    }

    :last-child{
        margin-bottom:0px;
    }
`;

export const ClientLogo = styled.div`
    width:120px;
    margin:0px auto;

    .client-logo{
        width:120px;
        height:auto;
    }
`;
