import styled from 'styled-components';
import {CommonbtnRound} from '../../../components/Common/common.style';
import BackgroundImg from '../../../assets/services-2-images/small-banner.jpg'
import {device} from '../../../components/Common/device';

export const CTABannerSection = styled.section`
    padding:100px 0px;
    background-image:url(${BackgroundImg});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    @media ${device.tablet} {
        padding:80px 5px;
    }
`;

export const TextBtnLayout = styled.div`
    max-width: 600px;

    h2{
        color:#fff;
    }
    p{
        color:#fff;
    }
`;




export const BannerBtn = styled(CommonbtnRound)`
    border-radius:25px;
`;
