import React from 'react';
import {ServicesOverviewSection,CustomRow,ImageHolder,TextLayout,
    FeaturesLayout,FeaturesTextLayout,FreeQuoteBtn
} from './servicesoverview.style';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import GatsImg from "gatsby-image";
import { useStaticQuery, graphql } from 'gatsby';
import ButtonArrow from "../../../components/Common/buttonarrow"

const ServicesOverview = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                servicesPage2Json{
                    ServicesOverview{
                       Heading
                       Description
                       Img{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                       }
                       FeaturesImg1
                       FeaturesHeading1
                       FeaturesDesc1
                       FeaturesImg2
                       FeaturesHeading2
                       FeaturesDesc2
                    }
                }
            }
    `);
    const ServicesOverviewData = JSONData.servicesPage2Json.ServicesOverview;
    return (
        <ServicesOverviewSection id="ServicesOverviewSection">
            <Container>
                <CustomRow>
                    <Col lg={6}>
                        <ImageHolder>
                            <GatsImg
                                fluid={ServicesOverviewData.Img.childImageSharp.fluid}
                                alt=""
                            />
                        </ImageHolder>
                    </Col>
                    <Col lg={6}>
                        <TextLayout>
                            <h2>
                                {ServicesOverviewData.Heading}
                            </h2>
                            <p>
                            {ServicesOverviewData.Description}
                            </p>

                            <Row>
                                <Col md={6} xs={6}>
                                    <FeaturesLayout>
                                        <img
                                            src={ServicesOverviewData.FeaturesImg1}
                                            alt=""
                                        />
                                        <FeaturesTextLayout>
                                            <h5>
                                            {ServicesOverviewData.FeaturesHeading1}
                                            </h5>
                                            <p>
                                            {ServicesOverviewData.FeaturesDesc1}
                                            </p>
                                        </FeaturesTextLayout>
                                    </FeaturesLayout>
                                </Col>
                                <Col md={6} xs={6}>
                                    <FeaturesLayout>
                                        <img
                                            src={ServicesOverviewData.FeaturesImg2}
                                            alt=""
                                        />
                                        <FeaturesTextLayout>
                                            <h5>
                                            {ServicesOverviewData.FeaturesHeading2}
                                            </h5>
                                            <p>
                                            {ServicesOverviewData.FeaturesDesc2}
                                            </p>
                                        </FeaturesTextLayout>
                                    </FeaturesLayout>
                                </Col>
                            </Row>

                            <FreeQuoteBtn href="/"><span>Get Free Quote</span><ButtonArrow/></FreeQuoteBtn>
                        </TextLayout>
                    </Col>
                </CustomRow>
            </Container>
        </ServicesOverviewSection>
    );
}

export default ServicesOverview;
